import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import React, { FC } from "react";

import { useUserService } from "../../services/user";
import ErrorDialog from "../ErrorDialog";
import * as styles from "./styles.module.scss";

/**
 * A root component for a page. Will show a loading screen when
 * no logged in state can be detected
 */
const PageRoot: FC = ({ children }) => {
    const { loading, user, error, resetError } = useUserService();

    if (loading || (!user && !error)) {
        return (
            <Container className={styles.container}>
                <Box className={styles.loading}></Box>
            </Container>
        );
    }

    if (error) {
        <Container className={styles.container}>
            <ErrorDialog error={error} reset={resetError} />
        </Container>;
    }

    return <>{children}</>;
};

export default PageRoot;

// extracted by mini-css-extract-plugin
export var grey120 = "#061227";
export var primary = "#757a97";
export var primary120 = "#5e6279";
export var primary40 = "#c8cad5";
export var secondary = "#081631";
export var secondary120 = "#061227";
export var secondary15 = "#dadce0";
export var secondary40 = "#9ca2ad";
export var nightblue = "#081631";
export var slumberblue = "#757a97";
export var linnengray = "#f2f2f2";
export var white = "#fff";
export var nightbluelight = "#3b455b";
// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-account-management-index-tsx": () => import("./../../../src/pages/account-management/index.tsx" /* webpackChunkName: "component---src-pages-account-management-index-tsx" */),
  "component---src-pages-cancel-index-tsx": () => import("./../../../src/pages/cancel/index.tsx" /* webpackChunkName: "component---src-pages-cancel-index-tsx" */),
  "component---src-pages-credit-selection-index-tsx": () => import("./../../../src/pages/credit-selection/index.tsx" /* webpackChunkName: "component---src-pages-credit-selection-index-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-failure-index-tsx": () => import("./../../../src/pages/failure/index.tsx" /* webpackChunkName: "component---src-pages-failure-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-recording-list-index-tsx": () => import("./../../../src/pages/recording-list/index.tsx" /* webpackChunkName: "component---src-pages-recording-list-index-tsx" */),
  "component---src-pages-sign-up-index-tsx": () => import("./../../../src/pages/sign-up/index.tsx" /* webpackChunkName: "component---src-pages-sign-up-index-tsx" */),
  "component---src-pages-success-index-tsx": () => import("./../../../src/pages/success/index.tsx" /* webpackChunkName: "component---src-pages-success-index-tsx" */),
  "component---src-pages-user-management-index-tsx": () => import("./../../../src/pages/user-management/index.tsx" /* webpackChunkName: "component---src-pages-user-management-index-tsx" */)
}


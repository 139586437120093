import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Link from "@material-ui/core/Link";
import { AxiosError } from "axios";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { shared } from "../../utils/global/shared";

interface Props {
    /**
     * The error passed to the component.
     * Can be either of type `AxiosError`, `Error` or `null`.
     */
    error: AxiosError | Error | null;
    /**
     * Defines the function to reset the error state
     */
    reset: () => void;
}

const ErrorDialog = ({ error, reset }: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Dialog open={!!error}>
            <DialogTitle>{t("ErrorTitle")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Trans i18nKey="ErrorText">
                        Something went wrong. Please try again. If this keeps
                        happening please contact us at
                        <Link href={`mailto:${shared.email}`} underline="hover">
                            {shared.email}
                        </Link>
                    </Trans>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={reset} color="primary">
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorDialog;

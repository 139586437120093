/**
 * I18next configuration
 */
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import EN from "../locales/en/translation.json";
import FR from "../locales/fr/translation.json";
import NL from "../locales/nl/translation.json";

const MATCH_LANGUAGE = /^([a-z]{2})/;

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: EN,
            },
            fr: {
                translation: FR,
            },
            nl: {
                translation: NL,
            },
        },
    });

/**
 * Fetch the detected language and normalize
 */
export const getLanguage = (): string => {
    const language = MATCH_LANGUAGE.exec(i18n.language);

    if (language?.length) {
        return language[0];
    }

    return i18n.language;
};

export default i18n;

import "./src/styles/index.scss";
import "./src/utils/i18n";

import React from "react";

import ApplicationRoot from "./src/components/ApplicationRoot";
import PageRoot from "./src/components/PageRoot";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wrapRootElement = ({ element }) => (
    <ApplicationRoot>{element}</ApplicationRoot>
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wrapPageElement = ({ element }) => <PageRoot>{element}</PageRoot>;

/**
 * A token aware api client for usage in the authenticated zone
 */
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

import packageJson from "../../../package.json";
import { refresh } from "../../services/user";

declare global {
    // eslint-disable-next-line no-var
    var __USER_PRESENT__: boolean;
}

const UNAUTH_CALL_WHITELIST = [["post", "/user"]];

/**
 * Test if a call needs to be done with credentials
 */
const withCredentials = (config: AxiosRequestConfig) =>
    !UNAUTH_CALL_WHITELIST.find(
        ([method, url]) =>
            config.url === url && config.method?.toLowerCase() === method,
    );

let client: AxiosInstance | null = null;

/**
 * Creates a client that will automatically set window.location.href to the
 * correct login url when a 401 was received from the server
 */
const create = () => {
    const client = axios.create({
        baseURL: process.env.GATSBY_API_BASE,
        // withCredentials: true,
    });

    // An interceptor that allows a dev to inject an overriding access token
    if (process.env.GATSBY_OVERRIDE_API_ACCESS_TOKEN) {
        client.interceptors.request.use(config => {
            return {
                ...config,
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${process.env.GATSBY_OVERRIDE_API_ACCESS_TOKEN}`,
                    "X-Client": `Web-${packageJson.version}`,
                },
            };
        });
    }

    // Adds the withCredentials url only when not a public route
    client.interceptors.request.use(config => {
        if (withCredentials(config)) {
            return {
                ...config,
                withCredentials: true,
            };
        }

        return config;
    });

    // Define a response interceptor that will set the page href to the login page
    // upon the receival of a 401
    client.interceptors.response.use(
        response => response,
        error => {
            // Was already logged in this session, silent renewal
            if (error?.response?.status === 401) {
                refresh();
            }

            return Promise.reject(error);
        },
    );

    return client;
};

/**
 * Gets or creates a client and returns instance when called
 */
export const getClient = (): AxiosInstance => {
    if (!client) {
        client = create();
    }
    return client;
};

/* eslint-disable sort-keys */
import { createTheme } from "@material-ui/core/styles";

import * as styles from "./export.module.scss";

export default createTheme({
    overrides: {
        // header
        MuiAppBar: {
            colorPrimary: {
                color: "white",
            },
        },

        // button
        MuiButton: {
            contained: {
                "&:hover, & :hover": {
                    backgroundColor: styles.primary120,
                },
                backgroundColor: styles.primary,
            },
            containedPrimary: {
                "&:hover, & :hover": {
                    backgroundColor: styles.secondary120,
                },
                backgroundColor: styles.primary120,
                color: "white",
                maxHeight: "3.25em",
            },
            containedSecondary: {
                "&:hover, & :hover": {
                    backgroundColor: styles.grey120,
                    color: styles.white,
                },
                backgroundColor: styles.linnengray,
                color: "black",
                maxHeight: "3.25em",
            },
            label: {
                transition:
                    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            },
            root: {
                borderRadius: 0,
                fontSize: ".75em",
                fontWeight: 400,
                letterSpacing: ".075em",
                padding: ".75em 3em",
            },
        },

        MuiCircularProgress: {
            colorPrimary: {
                backgroundColor: styles.primary40,
                borderRadius: "50%",
                color: styles.primary,
            },
            colorSecondary: {
                backgroundColor: styles.secondary40,
                borderRadius: "50%",
                color: styles.secondary,
            },
        },

        // container styling
        MuiContainer: {
            // screen wrapper
            maxWidthLg: {
                display: "grid",
                flex: "1 1 auto",
                gridGap: "2em",
                maxWidth: "100%",
                padding: "4em 2em",
                placeItems: "center",
            },

            // application wrapper
            maxWidthXl: {
                "@media (min-width: 1920px)": {
                    maxWidth: "100%",
                },
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                maxWidth: "100%",
            },
        },

        MuiFab: {
            root: {
                color: "white",
            },
            secondary: {
                color: "white",
            },
        },

        MuiFilledInput: {
            multiline: {
                height: "100%",
            },
            underline: {
                "&:after": {
                    borderBottomColor: styles.secondary,
                },
                "&:before": {
                    borderBottom: "none",
                },
                "&:hover:before": {
                    borderBottom: "none",
                },
            },
        },

        /**
         * @deprecated
         */
        // MuiDropzoneArea: {
        //     root: {
        //         backgroundColor: styles.grey,
        //         border: "none",
        //         padding: "0 1em",
        //     },
        //     textContainer: {
        //         display: "flex",
        //         alignItems: "center",
        //         marginBottom: "1em",
        //     },
        //     text: {
        //         fontSize: ".9em",
        //         letterSpacing: "0.075em",
        //         textAlign: "left",
        //         textTransform: "uppercase",
        //         color: styles.greyDark,
        //         marginTop: 0,
        //         marginBottom: 0,
        //     },
        //     icon: {
        //         transform: "scale(.6)",
        //         height: "42px",
        //     },
        // },
        /**
         * @deprecated
         */
        // MuiDropzonePreviewList: {
        //     root: {
        //         width: "100%",
        //         height: "12em",
        //         margin: "0 0 1em",
        //         flexDirection: "column",
        //         flexWrap: "nowrap",
        //         overflowY: "scroll",
        //     },
        //     imageContainer: {
        //         maxWidth: "100%",
        //         textAlign: "left",
        //         padding: "0 !important",
        //     },
        //     image: {
        //         display: "none",
        //     },
        // },
        MuiFormControlLabel: {
            root: {
                marginLeft: 0,
                marginRight: 0,
            },
        },

        MuiFormLabel: {
            root: {
                "&$focused": {
                    color: styles.secondary,
                },
            },
        },

        MuiIconButton: {
            colorSecondary: {
                "&:hover": {
                    backgroundColor: styles.secondary15,
                },
                color: styles.secondary,
            },
            root: {
                color: "white",
            },
        },

        MuiInputLabel: {
            filled: {
                color: styles.nightblue,
                fontSize: ".9em",
                letterSpacing: "0.075em",
                textTransform: "uppercase",
            },
        },

        MuiLinearProgress: {
            barColorSecondary: {
                backgroundColor: styles.secondary,
            },
            colorSecondary: {
                backgroundColor: styles.secondary40,
            },
            root: {
                marginTop: ".5em",
            },
        },

        MuiLink: {
            root: {
                fontFamily: ["'Product Sans'", "sans-serif"].join(","),
                fontSize: ".7em",
                fontWeight: 500,
                letterSpacing: ".15em",
                textTransform: "uppercase",
            },
        },

        MuiListItemIcon: {
            root: {
                color: "white",
                minWidth: "2em",
            },
        },

        MuiMenu: {
            paper: {
                backgroundColor: styles.primary120,
            },
        },

        MuiMenuItem: {
            root: {
                color: "white",
            },
        },

        MuiSelect: {
            icon: {
                color: styles.white,
            },
            root: {
                color: styles.white,
            },
        },

        // graphics
        MuiSvgIcon: {
            root: {
                fontSize: "1em",
            },
        },

        MuiTab: {
            root: {
                "&$selected": {
                    backgroundColor: styles.secondary,
                },
                backgroundColor: styles.secondary40,
            },
            textColorInherit: {
                color: "white",
                fontSize: ".75em",
                fontWeight: 400,
                letterSpacing: ".075em",
                opacity: 1,
            },
        },

        /**
         * @deprecated
         */
        // MuiTabPanel: {
        //     root: {
        //         alignSelf: "flex-start",
        //         padding: 0,
        //     },
        // },
        MuiTable: {
            root: {
                border: "none",
            },
        },

        MuiTableBody: {
            root: {
                backgroundColor: styles.linnengray,
            },
        },

        MuiTableCell: {
            body: {
                backgroundColor: "#F1F1F1",
                border: "none",
                borderBottom: "1rem solid #fff",
            },
            head: {
                backgroundColor: styles.secondary,
                border: "none",
                borderBottom: "1rem solid #fff",
                color: styles.white,
                fontWeight: 500,
                letterSpacing: "0.075em",
            },
            root: {
                textAlign: "left",
            },
        },

        MuiTableHead: {
            root: {
                color: "#fff",
            },
        },

        MuiTableSortLabel: {
            active: {
                color: "white!important",
                fontWeight: 700,
            },
            icon: {
                color: "currentColor!important",
            },
            root: {
                "&:hover, & :hover": {
                    color: styles.white,
                    fontWeight: 700,
                },
            },
        },

        // tabs
        MuiTabs: {
            indicator: {
                display: "none",
            },
            root: {
                alignSelf: "flex-end",
            },
        },

        // input field
        MuiTextField: {
            root: {
                height: "100%",
                width: "100%",
            },
        },

        MuiToolbar: {
            dense: {
                padding: ".75em 2em",
            },
            regular: {
                padding: "1em 2em",
            },
        },

        // text
        MuiTypography: {
            body1: {
                fontSize: "1em",
                fontWeight: 400,
                letterSpacing: ".075em",
            },
            caption: {
                color: "black",
                fontSize: ".7em",
                fontWeight: 500,
                letterSpacing: ".075em",
                textTransform: "uppercase",
            },
            h1: {
                fontSize: "1.5em",
                fontWeight: 500,
                letterSpacing: ".15em",
                textTransform: "uppercase",
            },
            subtitle1: {
                fontSize: ".7em",
                fontWeight: 300,
                letterSpacing: ".15em",
                textTransform: "uppercase",
            },
        },
    },

    palette: {
        primary: { main: styles.primary },
        secondary: { main: styles.secondary },
    },

    typography: {
        fontFamily: ["'Product Sans'", "sans-serif"].join(","),
    },
});

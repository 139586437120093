module.exports = [{
      plugin: require('../../../../../../../local-cache/custom/dba27c31aad935787bb275c3e5e4e957708f15386de599eff1db476022cd7e4c/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://bec2a98703f14376a78879660208579e@o933299.ingest.sentry.io/5980967"},
    },{
      plugin: require('../../../../../../../local-cache/custom/dba27c31aad935787bb275c3e5e4e957708f15386de599eff1db476022cd7e4c/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#ffffff","display":"standalone","icon":"src/assets/brand_logo.png","name":"Somnilog","short_name":"Somnilog","start_url":"/","theme_color":"#081631","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c014700c47ab7968416352babdfabd71"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

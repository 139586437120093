/**
 * A service that loads the user profile
 *
 * The user profile is only loaded once at the start of the application and
 * will remain fresh for the remainder of the session
 */
import { AxiosResponse } from "axios";
import { useEffect } from "react";
import { atom, SetterOrUpdater, useRecoilState } from "recoil";
import { resetRecoil } from "recoil-nexus";

import { getClient } from "../../utils/api";
import { getLanguage } from "../../utils/i18n";

// This marker is needed to remember to silently login or prompt the user with
// the choice to sign up or log in. Without this any user would be directed
// immediatly to the identity provider
declare global {
    // eslint-disable-next-line no-var
    var __USER_PRESENT__: boolean;
}

interface User {
    email: string;
    firstName: string;
    lastName: string;
    institution: string;
    jobTitle: string;
    slots: number;
    slotsUsed: number;
    vat: string;
    accountAdmin: boolean;
    licenseAgreements: string;
}

interface State {
    user: User | null;
    loading: boolean;
    error: Error | null;
}

interface Hook extends State {
    login: () => void;
    logout: () => void;
    update: (
        firstName: string,
        lastName: string,
        jobTitle: string,
    ) => Promise<void>;
    updateAccount: (vat: string, institution: string) => Promise<void>;
    updateLicenseAgreements: () => Promise<void>;
    requestPasswordChange: () => Promise<boolean>;
    resetError: () => void;
}

const SCOPE = ["openid", "profile"];
const LOGIN_URL = `https://${
    process.env.GATSBY_AUTH0_DOMAIN
}/authorize?scope=${SCOPE.join(" ")}&audience=${
    process.env.GATSBY_AUDIENCE
}&response_type=code&client_id=${
    process.env.GATSBY_AUTH0_CLIENT_ID
}&redirect_uri=${
    process.env.GATSBY_AUTH0_CALLBACK
}&ui_locales=${getLanguage()}`;
const LOGOUT_URL = `${process.env.GATSBY_API_BASE}/logout`;

let request: Promise<AxiosResponse<User>> | null = null;
let isLoggingIn = false;
let isLoggingOut = false;

const NAMESPACE = "services:user";
const INITIAL_STATE = {
    error: null,
    loading: false,
    user: null,
};

/**
 * Helper function that tests if a state is pristine
 */
const isPristine = (state: State) =>
    !state.loading && !state.user && !state.error;

// The actual state atom
const serviceState = atom<State>({
    default: INITIAL_STATE,
    key: NAMESPACE,
});

/**
 * Refresh: Silently refresh the user session
 *
 * Only do so when not previously logged in
 */
export const refresh = (): void => {
    // When a session was active in this runtime, silent refresh
    // Otherwise, clear the state
    if (globalThis.__USER_PRESENT__) {
        login();
    } else {
        // Reset the store and the router will pick up and show the user
        // the correct screen (login|sign-up)
        resetRecoil(serviceState);
    }
};

/**
 * Go to login
 */
export const login = (): void => {
    if (!isLoggingIn) {
        isLoggingIn = true;
        window.location.href = window.encodeURI(LOGIN_URL);
    }
};

/**
 * Go to logout
 */
export const logout = (): void => {
    // Clear user presence marker
    globalThis.__USER_PRESENT__ = false;
    if (!isLoggingOut) {
        isLoggingOut = true;
        window.location.href = window.encodeURI(LOGOUT_URL);
    }
};

/**
 * Update the user profile
 */
const update =
    (state: State, setState: SetterOrUpdater<State>) =>
    async (
        firstName: string,
        lastName: string,
        jobTitle: string,
    ): Promise<void> => {
        // Only operate when a user is present
        if (!state.user) {
            return;
        }

        const before = state.user ? { ...state.user } : null;

        try {
            // Attempt optimistic update
            setState({
                ...state,
                user: {
                    ...state.user,
                    firstName,
                    jobTitle,
                    lastName,
                },
            });

            await getClient().put("/user", { firstName, jobTitle, lastName });
        } catch (error) {
            setState({ ...state, error, user: before });
            // TODO: Convert to decent logging
            // eslint-disable-next-line no-console
            console.log("Rollback");
        }
    };

/**
 * Update the user account details
 */
const updateAccount =
    (state: State, setState: SetterOrUpdater<State>) =>
    async (vat: string, institution: string): Promise<void> => {
        // Only operate when a user is present
        if (!state.user) {
            return;
        }

        const before = state.user ? { ...state.user } : null;

        try {
            // Attempt optimistic update
            setState({
                ...state,
                user: {
                    ...state.user,
                    institution,
                    vat,
                },
            });

            await getClient().put("/account", { institution, vat });
        } catch (error) {
            setState({ ...state, error, user: before });
            // TODO: Convert to decent logging
            // eslint-disable-next-line no-console
            console.log("Rollback");
        }
    };

const updateLicenseAgreements =
    (state: State, setState: SetterOrUpdater<State>) =>
    async (): Promise<void> => {
        if (!state.user) {
            return;
        }

        const before = state.user ? { ...state.user } : null;

        const licenses = {
            acceptableUsePolicy: Date.now(),
            jointControllerPolicy: Date.now(),
            termsOfUse: Date.now(),
        };

        try {
            setState({
                ...state,
                user: {
                    ...state.user,
                    licenseAgreements: JSON.stringify(licenses),
                },
            });

            await getClient().put("/user", {
                ...state.user,
                licenseAgreements: JSON.stringify(licenses),
            });
        } catch (error) {
            setState({ ...state, error, user: before });
            // TODO: Convert to decent logging
            // eslint-disable-next-line no-console
            console.log("Rollback");
        }
    };

const requestPasswordChange =
    (state: State, setState: SetterOrUpdater<State>) =>
    async (): Promise<boolean> => {
        if (!state.user) {
            return false;
        }

        try {
            setState({
                ...state,
                loading: true,
            });

            await getClient().post("login/ask-reset-password", {
                email: state.user.email,
            });

            setState({
                ...state,
                loading: false,
            });

            return true;
        } catch (error) {
            setState({ ...state, error, loading: false });
            // eslint-disable-next-line no-console
            console.log("Rollback");

            return false;
        }
    };

const resetError = (state: State, setState: SetterOrUpdater<State>) => () => {
    setState({
        ...state,
        error: null,
    });
};

/**
 * A UserService hook that will auto-load the user when used on the client
 *
 * TODO: Add logout function
 */
export const useUserService = (): Hook => {
    const [state, setState] = useRecoilState(serviceState);

    useEffect(() => {
        if (isPristine(state)) {
            (async () => {
                // Block fetching when already in progress
                setState({ ...state, loading: true });

                try {
                    if (!request) {
                        request = getClient().get("/user");
                    }

                    const response = await request;
                    setState({ ...INITIAL_STATE, user: response.data });
                    globalThis.__USER_PRESENT__ = true;
                } catch (error) {
                    setState({ ...state, error, loading: false });
                    // TODO: Add error to logger
                    // eslint-disable-next-line no-console
                    console.log(error);
                } finally {
                    request = null;
                }
            })();
        }
    }, [state, setState]);

    return {
        ...state,
        login,
        logout,
        requestPasswordChange: requestPasswordChange(state, setState),
        resetError: resetError(state, setState),
        update: update(state, setState),
        updateAccount: updateAccount(state, setState),
        updateLicenseAgreements: updateLicenseAgreements(state, setState),
    };
};

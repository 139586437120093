import ThemeProvider from "@material-ui/styles/ThemeProvider";
import React, { FC } from "react";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";

import theme from "../../styles/theme";

/**
 * A root component for the application
 */
const ApplicationRoot: FC = ({ children }) => {
    return (
        <RecoilRoot>
            <RecoilNexus />
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </RecoilRoot>
    );
};

export default ApplicationRoot;
